
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { EmployerNoticeService } from "src/services";
import router from "src/router";
@Component
export default class ProductPage extends Vue {
  // 用于刷新子组件
  private keyNum: number = 0;

  // 样式
  private pageStyle: any = {};

  // 副标题样式
  private subStyle: any = {};

  // 余额
  private memberAssetData: number = 0;

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 父组件传值
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: {} })
  private data: any;

  /**
   * 余额
   * @private
   * @returns void
   */
  private async getMemberAssetData(): Promise<void> {
    try {
      let { content: result } = await EmployerNoticeService.instance.getMemberAsset(this.userInfo.userId);
      if (result && result.data) {
        this.memberAssetData = result.data || 0;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 获取企业名称
   * @private
   * @returns void
   */
  private get employerName(): any {
    return (this.$store.getters.orgInfo && this.$store.getters.orgInfo.orgName) || "";
  }

  /**
   * logoUrl
   * @private
   * @returns void
   */
  private get logoUrl(): any {
    return (this.$store.getters.orgInfo && this.$store.getters.orgInfo.logo) || "";
  }

  /**
   * 值发生变化
   * @private
   * @returns void
   */
  @Watch("data", { immediate: true, deep: true })
  private onDataChange(value: any): void {
    if (value) {
      this.pageStyle = {
        margin: value.properties.moduleSettings.upAndDown + "px 0 0 0",
        "background-image": value.properties.moduleSettings.bgImg.fullPath
          ? "url(" + value.properties.moduleSettings.bgImg.fullPath + ")"
          : "",
      };
      this.subStyle = {
        border: value.properties.moduleSettings.type == 1 ? "1px solid" + value.properties.moduleSettings.subTitleColor : "none",
        color: value.properties.moduleSettings.subTitleColor,
      };
      this.keyNum++;
      this.getMemberAssetData();
    }
  }
}
