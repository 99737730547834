
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "src/router";
@Component
export default class ArticlePage extends Vue {
  // 用于刷新子组件
  private keyNum: number = 0;

  // 样式
  private pageStyle: any = {};

  // 图片样式
  private imgStyle: any = {};

  /**
   * 父组件传值
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: {} })
  private data: any;

  /**
   * 页面跳转
   * @private
   */
  private onPageTo(id) {
    this.$router.push({ name: "health-product-article-detail", query: { id } });
  }

  /**
   * 值发生变化
   * @private
   * @returns void
   */
  @Watch("data", { immediate: true, deep: true })
  private onDataChange(value: any): void {
    if (value) {
      this.pageStyle = {
        margin:
          value.properties.moduleSettings.upAndDown +
          "px " +
          value.properties.moduleSettings.leftAndRight +
          "px 0 " +
          value.properties.moduleSettings.leftAndRight +
          "px",
        "background-color": value.properties.moduleSettings.bgColor,
        border: value.properties.moduleSettings.contourLine.show
          ? value.properties.moduleSettings.contourLine.width + "px solid" + value.properties.moduleSettings.contourLine.color
          : "",
        "border-radius":
          value.properties.moduleSettings.contourLine.show && !value.properties.moduleSettings.contourLine.corner ? "6px" : "",
      };
      this.imgStyle = { "border-radius": !value.properties.moduleSettings.imgCorner ? "6px" : "" };
      this.keyNum++;
    }
  }
}
