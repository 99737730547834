import { render, staticRenderFns } from "./page.vue?vue&type=template&id=fe02ca26&scoped=true"
import script from "./page.vue?vue&type=script&lang=ts"
export * from "./page.vue?vue&type=script&lang=ts"
import style0 from "./page.vue?vue&type=style&index=0&id=fe02ca26&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe02ca26",
  null
  
)

export default component.exports