
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "src/router";
@Component
export default class ProductPage extends Vue {
  // 用于刷新子组件
  private keyNum: number = 0;

  // 样式
  private pageStyle: any = {};

  // 图片样式
  private imgStyle: any = {};

  // 转换后显示的数据
  private tempData: Array<any> = [];

  /**
   * 父组件传值
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: {} })
  private data: any;

  /**
   * 详情跳转 -- 产品
   * @private
   */
  private onPageTo(item: any) {
    if (item.productType == 4) {
      this.$router.push({ name: "goodsDetail", query: { id: item.id } });
    } else if (item.productType == 8) {
      // 健康险产品
      this.$router.push({ name: "healthInsuranceDetail", query: { id: item.id } });
    } else {
      this.$router.push({ name: "medical", query: { id: item.id } });
    }
  }

  /**
   * 值发生变化
   * @private
   * @returns void
   */
  @Watch("data", { immediate: true, deep: true })
  private onDataChange(value: any): void {
    if (value) {
      this.pageStyle = {
        margin:
          value.properties.moduleSettings.upAndDown +
          "px " +
          value.properties.moduleSettings.leftAndRight +
          "px 0 " +
          value.properties.moduleSettings.leftAndRight +
          "px",
        "background-color": value.properties.moduleSettings.bgColor,
        border:
          value.properties.moduleSettings.contourLine && value.properties.moduleSettings.contourLine.show
            ? value.properties.moduleSettings.contourLine.width + "px solid" + value.properties.moduleSettings.contourLine.color
            : "",
        "border-radius":
          value.properties.moduleSettings.contourLine &&
          value.properties.moduleSettings.contourLine.show &&
          !value.properties.moduleSettings.contourLine.corner
            ? "6px"
            : "",
      };
      this.imgStyle = {
        width:
          value.properties.moduleSettings.typeSetting == 1 ? "80px" : value.properties.moduleSettings.typeSetting == 2 ? "100%" : "80px",
        "border-radius": !value.properties.moduleSettings.imgCorner ? "6px" : "",
      };
      this.keyNum++;
    }
  }
}
