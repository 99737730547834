
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { FeedbackService, PageService } from "src/services";

/**
 * tabbar
 * @class
 * @version 1.0.0
 */
@Component
export default class Tabbar extends Vue {
  /**
   * 选中下标
   * @protected
   * @returns number
   */
  protected activeIndex: any = 1;

  /**
   * 菜单配置
   * @protected
   * @returns any
   */
  protected menuConfiguration: any = {
    home: true,
    healthCenter: false,
    my: true,
  };

  /**
   * 权益生效数
   * @protected
   * @returns Number
   */
  protected rightsNumn: any = 0;

  /**
   * 底部配置
   * @private
   * @returns string
   */
  private bottomBarConfigs: any = [];

  /**
   * 获取未读消息
   * @private
   * @returns any
   */
  private get unReadMessageCount(): any {
    return this.$store.state.unReadMessageCount || 0;
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.getPageConfig();
    this.getUnReadMessage();
    if (this.$route.meta.tabbarName) {
      switch (this.$route.meta.tabbarName) {
        case "home":
          this.activeIndex = 1;
          break;
        case "health-product-home":
          this.activeIndex = 2;
          break;
        case "personal-center":
          this.activeIndex = 3;
          break;
      }
    }
  }

  /**
   * 获取未读消息
   */
  protected async getUnReadMessage(): Promise<any> {
    try {
      let { content: res } = await FeedbackService.instance.getUnReadMessage();

      this.$store.commit("SET_UNREAD_COUNT", res.data);
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 导航栏变更
   * @private
   * @returns void
   */
  private onTabbarChange(name: string): void {
    switch (this.activeIndex) {
      case 1:
        this.$router.push({ name: "home" });
        break;
      case 2:
        this.$router.push({ name: "health-product-home" });
        break;
      case 3:
        this.$router.push({ name: "personal-center" });
        break;
    }
  }

  /**
   * 客户端获取页面样式
   * @private
   * @returns void
   */
  private async getPageConfig(): Promise<any> {
    try {
      let { content: res } = await PageService.instance.getPageConfig();
      if (res && res.data) {
        let isShowCenter = res.data.enableHealthCenterPage;
        this.menuConfiguration.healthCenter = isShowCenter;

        let bottomBarConfigsRes = res.data.bottomBarConfigs;
        if (isShowCenter == false) {
          bottomBarConfigsRes = bottomBarConfigsRes.filter((item) => item.pageType != 2);
        }

        this.bottomBarConfigs = bottomBarConfigsRes;
      }
    } catch (err) {
      Toast(err);
    }
  }
}
