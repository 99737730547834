
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "src/router";
import { UserService, OrderService } from "src/services";
import { Dialog, Toast } from "vant";
import { log } from "console";

@Component
export default class BannerPage extends Vue {
  // 用于刷新子组件
  private keyNum: number = 0;

  /**
   * 父组件传值
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: {} })
  private data: any;

  // 用于刷新子组件
  private pageStyle: Object = {};

  /**
   * 是否显示权益中心
   * @private
   * @returns boolean
   */
  private hasRights: boolean = false;

  /**
   *  订单数量
   */
  private orderQuantity1: any = null;
  private orderQuantity2: any = null;
  private orderQuantity3: any = null;

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.getOrderQuantity();
  }

  /**
   * 获取订单数量
   * @private
   * @returns void
   */
  private async getOrderQuantity(): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.getOrderQuantity();
      if (result.data) {
        // 订单数量
        if (result.data.quantities) {
          result.data.quantities.forEach((item) => {
            if (item.status === 1) {
              this.orderQuantity1 = item.quantity;
            }
            if (item.status === 2) {
              this.orderQuantity2 = item.quantity;
            }
            if (item.status === 3) {
              this.orderQuantity3 = item.quantity;
            }
          });
        }
        this.hasRights = result.data.hasRights || false;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 值发生变化
   * @private
   * @returns void
   */
  @Watch("data", { immediate: true, deep: true })
  private onDataChange(value: any): void {
    if (value) {
      // 背景样式
      this.pageStyle = {
        margin: value.properties.moduleSettings.upAndDown + "px " + 16 + "px " + 0,
        "border-radius": value.properties.moduleSettings.corner ? "0px" : "8px",
      };
      this.keyNum++;
    }
  }

  /**
   * 跳转订单列表
   * @private
   * @returns void
   */
  public onOrderDetail(type: string): any {
    this.$router.push({
      name: "order-list",
      query: { type, isShow: this.data.properties.moduleSettings.showActivated ? "true" : "false" },
    });
  }
}
