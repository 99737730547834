var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.keyNum,style:('margin:' +
    _vm.data.properties.moduleSettings.upAndDown +
    'px ' +
    _vm.data.properties.moduleSettings.leftAndRight +
    'px 0 ' +
    _vm.data.properties.moduleSettings.leftAndRight +
    'px')},[_c('van-swipe',{staticClass:"swper home-swper",attrs:{"autoplay":_vm.switchingSpeeFilter,"indicator-color":"white"}},_vm._l((_vm.data.properties.dataModel),function(d,k){return _c('van-swipe-item',{key:k,on:{"click":function($event){return _vm.onPageTo(d.data)}}},[_c('div',{staticClass:"home-swper-li"},[(d.data.type == 5 || d.data.type == 405 || d.data.type == 406)?_c('div',{staticClass:"minWx-banner-class",attrs:{"id":'minWx' + 'banner' + k + '-' + d.data.type,"t":d.data.type,"u":d.data.type == 5 ? d.data.link.url : '',"a":d.data.type == 5 ? d.data.link.appId : ''}}):_vm._e(),(d.imgUrl)?_c('img',{staticClass:"phone-img",class:{ corner: !_vm.data.properties.moduleSettings.corner },attrs:{"src":d.imgUrl}}):_vm._e()])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }